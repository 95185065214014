import { ToggleButton, ToggleButtonProps, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import { deepmerge } from '@mui/utils';

import React from 'react';

const buttonTheme = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          borderRadius: '4px',
          textTransform: 'capitalize',
          height: '28px',
          fontSize: '13px',
        },
      },
    },
  },
});

export const QToggleButton = React.forwardRef<HTMLButtonElement, ToggleButtonProps>((props, ref) => {
  return (
    <ThemeProvider theme={outerTheme => deepmerge(buttonTheme, outerTheme)}>
      <ToggleButton ref={ref} {...props}>
        {props.children}
      </ToggleButton>
    </ThemeProvider>
  );
});

QToggleButton.displayName = 'QToggleButton';
