import { Chip, ChipProps, alpha, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { theme } from '../../theme';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    primaryDark: true;
    primaryLight: true;
    secondary: true;
    quokkapedia: true;
    quokkapediaLarge: true;
    maroon: true;
  }
}

interface IProps extends ChipProps {
  label?: string;
  labelPadding?: string;
  fontSize?: string;
  fontWeight?: number;
  variant?: 'primaryDark' | 'primaryLight' | 'secondary' | 'quokkapedia' | 'quokkapediaLarge';
}

export const QChip = ({ label, labelPadding, fontSize, fontWeight, ...props }: IProps) => {
  const getChipTheme = (palette = theme.palette) =>
    createTheme({
      components: {
        MuiChip: {
          variants: [
            {
              props: { variant: 'primaryDark' },
              style: {
                color: palette.base.white,
                backgroundColor: palette.text.secondary,
              },
            },
            {
              props: { variant: 'primaryLight' },
              style: {
                color: palette.base.black,
                backgroundColor: alpha(palette.primary.light, 0.6),
              },
            },
            {
              props: { variant: 'secondary' },
              style: {
                color: palette.base.red,
                backgroundColor: palette.base.white,
                borderBlockColor: palette.base.red,
                border: '1px solid',
              },
            },
            {
              props: { variant: 'quokkapedia' },
              style: {
                color: palette.base.white,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '21px',
                backgroundColor: palette.quokkapedia.light,
              },
            },
            {
              props: { variant: 'quokkapediaLarge' },
              style: {
                color: palette.base.white,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '21px',
                height: '37px',
                padding: '8px 16px 8px 16px',
                borderRadius: '16px',
                backgroundColor: palette.quokkapedia.light,
              },
            },
          ],
          styleOverrides: {
            root: {
              borderRadius: '12px',
              height: '24px',
              textTransform: 'capitalize',
              textAlign: 'center',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
            },
          },
        },
      },
    });
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getChipTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Chip
        {...props}
        label={label}
        sx={{
          '& .MuiChip-label': {
            padding: labelPadding,
            fontSize,
            fontWeight,
          },
        }}
      />
    </ThemeProvider>
  );
};
