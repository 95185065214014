import { Autocomplete, AutocompleteProps, ThemeProvider, alpha, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { theme } from '../../theme';

const getAutocompleteTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiAutocomplete-popupIndicatorOpen': { color: palette.text.secondary },
          },
          popper: {
            '.MuiAutocomplete-paper': { boxShadow: 'none' },
          },
          listbox: {
            '.MuiAutocomplete-option': {
              '&.Mui-focused': {
                backgroundColor: alpha(palette.primary.main, 0.2),
              },
            },
            '.MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: `${alpha(palette.primary.main, 0.4)} !important`,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            padding: '5px 9px 4px 9px',
          },
        },
      },
    },
  });
// AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> requires between 4 and 5 type arguments and no children
export const QAutocomplete = ({ ...props }: AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getAutocompleteTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Autocomplete {...props} />
    </ThemeProvider>
  );
};
