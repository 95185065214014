import React from 'react';
import { deepmerge } from '@mui/utils';
import { Alert, AlertProps, AlertTitle, ThemeProvider, createTheme } from '@mui/material';
import { DoNotDisturbOutlined, ErrorOutlineOutlined, CheckCircleOutlined, InfoOutlined } from '@mui/icons-material';

import { theme } from '../../theme';

const getAlertTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '20px',
            color: palette.greyOverride[700],
            backgroundColor: palette.base.white,
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '21px',
            color: palette.base.black,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: '20px',
            height: '20px',
          },
        },
      },
    },
  });

export const QAlert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getAlertTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Alert
        ref={ref}
        iconMapping={{
          error: <DoNotDisturbOutlined />,
          warning: <ErrorOutlineOutlined />,
          info: <InfoOutlined />,
          success: <CheckCircleOutlined />,
        }}
        {...props}
      >
        <AlertTitle>{props.title}</AlertTitle>
        {props.children}
      </Alert>
    </ThemeProvider>
  );
});

QAlert.displayName = 'QAlert';
