import { Divider, DividerProps, ThemeProvider, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';

const getDividerTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: palette.base.black,
          },
        },
      },
    },
  });

export const QDivider = ({ children, ...props }: DividerProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDividerTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Divider {...props}>{children}</Divider>
    </ThemeProvider>
  );
};
