import {
  AppBar,
  AppBarProps,
  Container,
  ContainerProps,
  IconButton,
  IconButtonProps,
  ThemeProvider,
  Toolbar,
  ToolbarProps,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';
import { QTypography } from '../QTypography/QTypography';
import { createQTheme } from '../utils/createQTheme';

const getAppBarTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            border: 'none',
            borderBottom: '1px solid',
            borderBottomColor: palette.greyOverride[400],
            boxShadow: 'none',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: palette.greyOverride[50],
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            display: 'flex',
            justifyContent: 'end',
            columnGap: '50px',
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          fixed: true,
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            display: 'flex',
            alignContent: 'center',
            columnGap: '16px',
            flexGrow: 1,
            padding: '0px',
            margin: '0px',
            maxWidth: 'none !important',
            lineHeight: '45px',
            '.MuiTypography-h2Regular': {
              color: palette.text.secondary,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: palette.base.black,
            padding: '0px',
          },
        },
      },
    },
  });

interface TitleContainerProps extends ContainerProps {
  title?: string;
}

export const QHeader = ({ children, ...props }: AppBarProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getAppBarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <AppBar {...props}>{children}</AppBar>
    </ThemeProvider>
  );
};

export const QHeaderToolbar = ({ children, ...props }: ToolbarProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getAppBarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      {' '}
      <Toolbar {...props}>{children}</Toolbar>
    </ThemeProvider>
  );
};

export const QHeaderIconButton = ({ children, ...props }: IconButtonProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getAppBarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      {' '}
      <IconButton {...props}>{children}</IconButton>
    </ThemeProvider>
  );
};

export const QHeaderTitleContainer = ({ title, children, ...props }: TitleContainerProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getAppBarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      {' '}
      <Container {...props}>
        {children}
        <QTypography variant="h2Regular">{title}</QTypography>
      </Container>
    </ThemeProvider>
  );
};
