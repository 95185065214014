import { IconButton, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import { deepmerge } from '@mui/utils';

import React from 'react';
import { theme } from '../../theme';

const getButtonTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            width: '20px',
            height: '20px',
            borderRadius: 0,
            border: '1px transparent',
            color: palette.primary.main,
          },
        },
      },
    },
  });

export const QIconButton = React.forwardRef<HTMLButtonElement, any>((props, ref) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getButtonTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <IconButton ref={ref} {...props}>
        {props.children}
      </IconButton>
    </ThemeProvider>
  );
});

QIconButton.displayName = 'QIconButton';
