import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  ThemeProvider,
  alpha,
  FilledInput,
  Checkbox,
  Select,
  FormControlProps,
  InputLabelProps,
  MenuItemProps,
  createTheme,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { ExpandMore } from '@mui/icons-material';
import { deepmerge } from '@mui/utils';

import { Link } from 'react-router-dom';

import { mergeDeep } from '../utils/mergeDeep';
import { theme } from '../../theme';
import { QBadge } from '../QBadge/QBadge';
import { QButton } from '../QButton/QButton';

const getDropdownTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            padding: '8px 4px 8px 4px',
            height: '32px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            height: '21px',
            color: palette.base.black,
            transform: 'translate(12px, 14px) scale(1)',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
          },
          filled: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignSelf: 'center',
            fontSize: '14px',
            paddingRight: '4px',
          },
          outlined: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignSelf: 'center',
            fontSize: '14px',
            lineHeight: '1.4375em',
            paddingRight: '4px',
          },
          icon: {
            position: 'absolute',
            right: '0',
            top: 'unset',
            pointerEvents: 'none',
            margin: '8px',
            color: palette.base.black,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minWidth: '145px',
            height: '36px',
            flexShrink: '0',
            fontSize: '14px',
            lineHeight: '20px',
            backgroundColor: palette.greyOverride[50],
            '&:hover, &.hover': {
              backgroundColor: alpha(palette.primary.main, 0.2),
            },
            '&:focus': {
              backgroundColor: alpha(palette.primary.main, 0.4),
            },
            '&.Mui-selected': {
              backgroundColor: alpha(palette.primary.main, 0.4),
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '28px',
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            backgroundColor: palette.greyOverride[200],
            height: '36px',
            columnGap: '3px',
            borderRadius: '4px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: '36px',
            columnGap: '3px',
          },
          notchedOutline: {
            height: '36px',
            borderColor: palette.primary.main,
          },
        },
      },
      MuiImageListItem: {
        styleOverrides: {
          img: {
            filter: palette.greyFilter[800],
            width: '14px',
            height: '8px',
            marginLeft: '8px',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
          },
        },
      },
    },
  });

export type DropdownOptionType = { value: any; label: any; icon?: any; link?: string; disabled?: boolean; onClick?: any; id?: string }[];

interface QDropdownWrapperProps {
  options?: DropdownOptionType;
  selectedOption?: any;
  onChange?: any;
  closedLabel?: any;
  formControlProps?: FormControlProps;
  labelProps?: InputLabelProps;
  selectProps?: SelectProps;
  itemProps?: MenuItemProps;
  labelButtonProps?: any;
  variant?: 'standard' | 'outlined' | 'filled';
}

// TODO: Rename this to QDropdown when we have replaced all the QDropdown references in emm-frontend
export const QDropdownWrapper = ({
  options = [],
  selectedOption = '',
  onChange = null,
  closedLabel = '',
  formControlProps = {},
  labelProps = {},
  selectProps = {},
  itemProps = {},
  labelButtonProps = null,
  variant = 'outlined',
}: QDropdownWrapperProps) => {
  function renderMenuItem(option) {
    const menuItem = (
      <QMenuItem
        key={option.value}
        value={option.value}
        className={selectedOption && selectedOption.value === option.value ? 'hover' : ''}
        onClick={option.onClick}
        disabled={option.disabled}
        id={option.id}
        {...itemProps}
      >
        {option.icon ? (
          <QButton variant="ghost" startIcon={option.icon} style={{ justifyContent: 'flex-start' }}>
            {option.label}
          </QButton>
        ) : (
          option.label
        )}
      </QMenuItem>
    );

    if (option.link && !option.disabled) {
      return (
        <Link to={option.link} key={option.value} style={{ textDecoration: 'none', color: 'inherit' }}>
          {menuItem}
        </Link>
      );
    }
    return menuItem;
  }

  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <QDropdown {...formControlProps}>
        <QInputLabel {...labelProps}>{labelButtonProps ? <QButton {...labelButtonProps}>{closedLabel}</QButton> : closedLabel}</QInputLabel>
        <QDropdownSelect
          label={closedLabel}
          value={selectedOption}
          onChange={onChange}
          variant={variant}
          renderValue={() => null}
          {...selectProps}
        >
          <QMenuItem key={''} value={''} sx={{ display: 'none' }} />
          {options.map(option => renderMenuItem(option))}
        </QDropdownSelect>
      </QDropdown>
    </ThemeProvider>
  );
};

export const QDropdown = ({ children, ...props }: FormControlProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <FormControl {...props}>{children}</FormControl>
    </ThemeProvider>
  );
};

export const QInputLabel = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <InputLabel {...props}>{children}</InputLabel>
    </ThemeProvider>
  );
};

export const QDropdownSelect = ({ menuItemCount, MenuProps, children, ...props }: SelectProps & { menuItemCount?: string }) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Select
        MenuProps={mergeDeep(
          {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
            MenuListProps: { sx: { padding: 0 } },
            PaperProps: {
              sx: {
                borderRadius: '0px',
              },
            },
          },
          MenuProps,
        )}
        IconComponent={_props => <SelectArrow menuItemCount={menuItemCount} {..._props} />}
        input={props.variant === 'outlined' ? <OutlinedInput /> : <FilledInput />}
        {...props}
      >
        {children}
      </Select>
    </ThemeProvider>
  );
};

export const QMenuItem = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <MenuItem {...props}>{children}</MenuItem>
    </ThemeProvider>
  );
};

export const QCheckbox = ({ checked, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Checkbox checked={checked} {...props} />
    </ThemeProvider>
  );
};

interface SelectArrowProps {
  menuItemCount?: string;
}

const SelectArrow = ({ menuItemCount, ...props }: SelectArrowProps) => (
  <>
    {typeof menuItemCount !== 'undefined' && (
      <QBadge
        label={menuItemCount}
        variant="counter"
        sx={{ position: 'absolute !important', right: '20 !important', pointerEvents: 'none !important' }}
      />
    )}
    <ExpandMore {...props} />
  </>
);
