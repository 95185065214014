import { ThemeProvider, createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import _ from 'lodash';
import { theme } from '../theme';

interface QThemeProviderProps {
  children: any;
  customFonts?: string[];
  customTheme?: any;
  customPalette?: any;
}

/**
 * QThemeProvider component
 *
 * This component provides a custom theme to its children components using Material-UI's ThemeProvider.
 * It allows for overriding and extending the default theme with custom fonts and a custom theme object.
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components that will receive the custom theme.
 * @param {string[]} [props.customFonts] - An optional array of custom font family names to override the default fonts in the theme.
 * @param {object} [props.customTheme={}] - An optional object containing custom theme settings that override the default theme.
 * Allows specifying any theme attributes that need to be changed, while keeping other attributes as default.
 * Should use with caution because it will change the default theme of Q-Components
 * @param {object} [props.customPalette={}] - An optional object containing custom palette settings that override the default palette in the theme.
 * Allows specifying any theme attributes that need to be changed, while keeping other attributes as default.
 * Should use with caution because it will change the default palette of Q-Components.
 *
 * @example
 * <QThemeProvider customFonts={['Arial', 'Roboto']} customTheme={{ palette: { primary: { main: '#FF5722' } } }} customPalette={{ primary: { main: '#FF5722' } }}>
 *   <YourComponent />
 * </QThemeProvider>
 */
export const QThemeProvider = ({ children, customFonts, customPalette = {}, customTheme = {} }: QThemeProviderProps) => {
  const fonts =
    _.isArray(customFonts) && customFonts[0] && _.every(customFonts, item => typeof item === 'string')
      ? customFonts.join(',')
      : ['"Nunito Sans"', 'NunitoSans', 'sans-serif'].join(',');
  const fontsAndPaletteTheme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: fonts,
          },
        },
      },
    },
    typography: {
      fontFamily: fonts,
      allVariants: {
        fontFamily: fonts,
      },
    },
    palette: { ...theme.palette, ...customPalette },
  });

  const customFontsAndPaletteTheme = createTheme(deepmerge(theme, fontsAndPaletteTheme));
  const finalCustomTheme = createTheme(deepmerge(customFontsAndPaletteTheme, customTheme));

  return <ThemeProvider theme={finalCustomTheme}>{children}</ThemeProvider>;
};
