import { Box, Divider, ThemeProvider, Tooltip, TooltipProps } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';
import { createQTheme } from '../utils/createQTheme';

const getTooltipTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          arrow: {
            color: palette.greyOverride.tooltip,
          },
          tooltip: {
            backgroundColor: palette.greyOverride.tooltip,
            paddingTop: '1px',
            paddingLeft: '23.5px',
            paddingRight: '23.5px',
            paddingBottom: '3px',
            maxWidth: '252px',
            minHeight: '24px',
            fontSize: '14px',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            lineHeight: '20px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: palette.base.white,
            marginTop: '4px',
            marginBottom: '8px',
          },
        },
      },
    },
  });

interface IProps {
  headline?: string;
  subheadline?: string;
  actions?: any;
  children?: any;
}

export const QTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTooltipTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Tooltip {...props}>{children}</Tooltip>
    </ThemeProvider>
  );
};

export const QTooltipContent = ({ headline, subheadline, actions, children }: IProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTooltipTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      {(headline || subheadline) && (
        <Box paddingTop={'11px'} paddingBottom={'18px'}>
          {headline}
          {subheadline === undefined || headline === undefined ? <></> : <Divider></Divider>}
          {subheadline}
        </Box>
      )}
      {children && <Box paddingBottom={'18px'}>{children}</Box>}
      {actions && (
        <Box marginTop={'-9px'} flexDirection={'row-reverse'} alignItems={'flex-end'} display={'flex'}>
          {actions}
        </Box>
      )}
    </ThemeProvider>
  );
};
