import { Divider, ListItemText, MenuItem, ThemeProvider, Typography, Menu } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';
import { createQTheme } from '../utils/createQTheme';

const getProfileTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            display: 'inline-flex',
            paddingLeft: '16px',
            paddingRight: '16px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '8px',
            minWidth: '167px',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            minWidth: '135px',
            padding: '0px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minWidth: '167px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: palette.base.black,
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '21px',
            paddingLeft: '0px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: '12px',
            marginBottom: '12px',
          },
        },
      },
    },
  });

const getHeaderTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: palette.base.black,
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '25px',
          },
          secondary: {
            color: palette.base.grey,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
          },
        },
      },
    },
  });

const getSubHeaderTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: palette.base.grey,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '20px',
          },
        },
      },
    },
  });

const stopPropagationForTab = event => {
  if (event.key === 'Tab') {
    event.stopPropagation();
  }
};

export const QProfileHeader = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getHeaderTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ListItemText {...props} tabIndex={-1}>
        {children}
      </ListItemText>
    </ThemeProvider>
  );
};

export const QProfileSubHeader = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSubHeaderTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Typography {...props}>{children}</Typography>
    </ThemeProvider>
  );
};

export const QProfileItem = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getProfileTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <MenuItem {...props} tabIndex={0} onKeyDown={stopPropagationForTab}>
        {children}
      </MenuItem>
    </ThemeProvider>
  );
};

export const QProfileMenu = ({ header, subHeaders, items, children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getProfileTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Menu {...props}>
        {header}
        {subHeaders}
        {items && (header || subHeaders) && <Divider />}
        {items}
      </Menu>
    </ThemeProvider>
  );
};
