import { Tab, TabProps, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { TabContext, TabContextProps, TabList, TabListProps, TabPanel, TabPanelProps } from '@mui/lab';

import { theme } from '../../theme';
import { createQTheme } from '../utils/createQTheme';

const getTabsTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: palette.primary.main,
          },
          root: {
            borderBottom: '1px solid',
            borderColor: palette.greyOverride[400],
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            fontSize: '18px',
            fontStyle: 'normal',
            lineHeight: '25px',
            padding: '0px 40px 20.5px 40px',
            textTransform: 'capitalize',
            color: palette.base.black,
            '&.Mui-selected': {
              color: palette.base.black,
            },
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            fontFamily: theme.typography.fontFamily,
          },
        },
      },
    },
  });

export const QTabList = ({ children, ...props }: TabListProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTabsTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TabList {...props}>{children}</TabList>
    </ThemeProvider>
  );
};

export const QTab = ({ ...props }: TabProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTabsTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Tab {...props} />
    </ThemeProvider>
  );
};

export const QTabContext = ({ children, ...props }: TabContextProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTabsTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TabContext {...props}>{children}</TabContext>
    </ThemeProvider>
  );
};

export const QTabPanel = ({ children, ...props }: TabPanelProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTabsTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TabPanel {...props}>{children}</TabPanel>
    </ThemeProvider>
  );
};
