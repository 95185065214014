import { Button, ButtonProps, createTheme, lighten } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import React from 'react';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';

const getButtonTheme = (palette = theme.palette) => {
  return createTheme({
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'filled' },
            style: {
              color: 'white',
              backgroundColor: palette.primary.main,
              '&:hover, &:focus-visible': {
                backgroundColor: lighten(palette.primary.main, 0.8),
              },
              '&:active': {
                backgroundColor: lighten(palette.primary.main, 0.6),
              },
              '&:disabled': {
                color: 'white',
                backgroundColor: palette.base.disabled,
              },
            },
          },
          {
            props: { variant: 'light' },
            style: {
              color: palette.primary.main,
              backgroundColor: 'white',
              '&:hover, &:focus-visible': {
                backgroundColor: lighten(palette.secondary.main, 0.8),
              },
              '&:active': {
                color: 'white',
                backgroundColor: palette.primary.main,
              },
              '&:disabled': {
                color: palette.greyOverride[400],
                backgroundColor: 'white',
              },
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              color: palette.primary.main,
              backgroundColor: 'white',
              borderBlockColor: palette.primary.main,
              border: '1px solid',
              '&:hover, &:focus-visible': {
                color: lighten(palette.primary.main, 0.8),
                borderColor: lighten(palette.primary.main, 0.8),
                backgroundColor: 'white',
                border: '1px solid',
              },
              '&:active': {
                border: '1px solid',
                color: lighten(palette.primary.main, 0.6),
                borderColor: lighten(palette.primary.main, 0.6),
              },
              '&:disabled': {
                color: palette.greyOverride[400],
                border: '1px solid #E0E0E0',
              },
            },
          },
          {
            props: { variant: 'basic' },
            style: {
              color: palette.primary.main,
              backgroundColor: 'white',
              '&:hover, &:focus-visible': {
                backgroundColor: 'white',
                color: lighten(palette.primary.main, 0.8),
              },
              '&:active': {
                color: lighten(palette.primary.main, 0.6),
              },
              '&:disabled': {
                color: palette.greyOverride[400],
              },
            },
          },
          {
            props: { variant: 'ghost' },
            style: {
              color: palette.base.black,
              backgroundColor: 'transparent',
              textTransform: 'none',
              padding: '0px',
              '&:hover, &:focus-visible': {
                backgroundColor: 'transparent',
                color: lighten(palette.base.black, 0.8),
              },
              '&:active': {
                color: lighten(palette.base.black, 0.6),
              },
              '&:disabled': {
                color: palette.greyOverride[400],
              },
            },
          },
        ],
        defaultProps: {
          // The props to apply
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
        styleOverrides: {
          root: {
            textAlign: 'center',
            borderRadius: '4px',
            lineHeight: '30px',
            textTransform: 'capitalize',
            height: '32px',
            fontSize: '13px',
            fontWeight: 700,
            minWidth: '74px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '1px',
            paddingTop: '1px',
          },
        },
      },
    },
  });
};

interface IProps extends ButtonProps {
  children?: any;
  variant?: 'filled' | 'light' | 'basic' | 'outlined' | 'ghost';
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    filled: true;
    light: true;
    outlined: true;
    basic: true;
    ghost: true;
  }
}

export const QButton = React.forwardRef<HTMLButtonElement, IProps>((props, ref) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getButtonTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Button
        ref={ref}
        {...props}
        onFocus={event => {
          event.target.style.outline = 'none';
        }}
      >
        {props.children}
      </Button>
    </ThemeProvider>
  );
});

QButton.displayName = 'QButton';
