import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ThemeProvider,
  Checkbox,
  SvgIconProps,
  alpha,
  FormControlProps,
  InputLabelProps,
  MenuItemProps,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import { SelectProps } from '@mui/material/Select';
import { ExpandMore } from '@mui/icons-material';

import { theme } from '../../theme';
import { createQTheme } from '../utils/createQTheme';

const getDropdownTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.greyOverride['600'],
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: 'unset',
          },
          iconOpen: {
            color: palette.text.secondary,
          },
          select: {
            color: palette.base.black,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: palette.greyOverride['600'],
            '&.Mui-focused': {
              color: `${palette.text.secondary} !important`,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover, &.hover': {
              backgroundColor: alpha(palette.primary.main, 0.2),
            },
            '&:focus': {
              backgroundColor: alpha(palette.primary.main, 0.4),
            },
            '&.Mui-selected': {
              backgroundColor: alpha(palette.primary.main, 0.4),
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
          },
        },
      },
    },
  });
type OptionType = { value: any; label: any }[];
interface QSelectProps {
  options?: OptionType;
  value?: any;
  onChange?: any;
  label?: string;
  multiple?: boolean;
  size?: 'small' | 'medium';
  formControlProps?: FormControlProps;
  labelProps?: InputLabelProps;
  selectProps?: SelectProps;
  selectIconProps?: SvgIconProps;
  itemProps?: MenuItemProps;
  onOpen?: any;
  onClose?: any;
}

export const QSelect = ({
  options = [],
  value = '',
  onChange = null,
  label = '',
  multiple = false,
  size = 'small',
  formControlProps = {},
  labelProps = {},
  selectProps = {},
  selectIconProps = {},
  itemProps = {},
  onOpen = null,
  onClose = null,
}: QSelectProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <QNotchedDropdown size={size} {...formControlProps}>
        <QNotchedDropdownLabel {...labelProps}>{label}</QNotchedDropdownLabel>
        <QNotchedDropdownSelect
          label={label}
          value={value}
          onChange={onChange}
          multiple={multiple}
          IconProps={selectIconProps}
          renderValue={(selected: any) => {
            return multiple
              ? options
                  .filter(item => selected.includes(item.value))
                  .map(item => item.label)
                  .join(', ')
              : options.find(item => item.value === selected).label;
          }}
          onOpen={onOpen}
          onClose={onClose}
          {...selectProps}
        >
          <QNotchedDropdownItem key={''} value={''} sx={{ display: 'none' }} />
          {options.map(option => (
            <QNotchedDropdownItem key={option.value} value={option.value} {...itemProps}>
              {multiple && Object.prototype.toString.call(value) === '[object Array]' && (
                <QNotchedDropdownCheckbox checked={value.indexOf(option.value) > -1} />
              )}
              {option.label}
            </QNotchedDropdownItem>
          ))}
        </QNotchedDropdownSelect>
      </QNotchedDropdown>
    </ThemeProvider>
  );
};

const QNotchedDropdown = ({ children, ...props }: FormControlProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <FormControl {...props}>{children}</FormControl>
    </ThemeProvider>
  );
};

const QNotchedDropdownLabel = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <InputLabel {...props}>{children}</InputLabel>
    </ThemeProvider>
  );
};

const QNotchedDropdownSelect = ({ IconProps, children, ...props }: SelectProps & { IconProps?: SvgIconProps }) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Select
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: 'none',
            },
          },
        }}
        IconComponent={_props => <FilterEnd IconProps={IconProps} {..._props} />}
        {...props}
      >
        {children}
      </Select>
    </ThemeProvider>
  );
};

const QNotchedDropdownItem = ({ children, ...props }: MenuItemProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getDropdownTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <MenuItem {...props}>{children}</MenuItem>
    </ThemeProvider>
  );
};

const QNotchedDropdownCheckbox = ({ checked, ...props }: any) => {
  return <Checkbox checked={checked} {...props} />;
};

interface FilterEndProps {
  IconProps?: SvgIconProps;
}

const FilterEnd = ({ IconProps, ...props }: FilterEndProps) => <ExpandMore {...IconProps} {...props} />;
