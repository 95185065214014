import { createTheme } from '@mui/material/styles';
import React from 'react';

type TextType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body' | 'small' | 'tiny';

type FormTextType = 'placeholder' | 'label14' | 'label12';

type FormTextStyle = 'populated' | 'rest' | 'disable' | 'active' | 'medium' | 'regular';

type TextStyle = 'bold' | 'semiBold' | 'medium' | 'regular' | 'light' | 'code';

type KwTypography = `${TextType}${Capitalize<TextStyle>}`;
type KwFormTypography = `${FormTextType}${Capitalize<FormTextStyle>}`;

declare module '@mui/material/styles' {
  // eslint-ignore-next-line no-empty-interface
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariants extends Partial<Record<KwTypography | KwFormTypography, React.CSSProperties>> {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariantsOptions extends Partial<Record<KwTypography | KwFormTypography, React.CSSProperties>> {}

  interface Palette {
    accent: {
      primary: React.CSSProperties['color'];
      secondary: React.CSSProperties['color'];
      primaryFilter: React.CSSProperties['color'];
      100: React.CSSProperties['color'];
      200: React.CSSProperties['color'];
      400: React.CSSProperties['color'];
      500: React.CSSProperties['color'];
      filter500: React.CSSProperties['color'];

      gold: React.CSSProperties['color'];
      redOrange: React.CSSProperties['color'];
    };
    base: {
      maroon: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      grey: React.CSSProperties['color'];
      orange: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
      black: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      disabled: React.CSSProperties['color'];
    };
    state: {
      error: React.CSSProperties['color'];
      danger: React.CSSProperties['color'];
      info: React.CSSProperties['color'];
      success: React.CSSProperties['color'];
      warning: React.CSSProperties['color'];
      errorFilter: React.CSSProperties['color'];
      dangerFilter: React.CSSProperties['color'];
      infoFilter: React.CSSProperties['color'];
      successFilter: React.CSSProperties['color'];
      warningFilter: React.CSSProperties['color'];
    };
    chart: {
      amber: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      blueGrey: React.CSSProperties['color'];
      brown: React.CSSProperties['color'];
      cyan: React.CSSProperties['color'];
      deepOrange: React.CSSProperties['color'];
      deepPurple: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      grey: React.CSSProperties['color'];
      indigo: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
      lime: React.CSSProperties['color'];
      orange: React.CSSProperties['color'];
      pink: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      teal: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
    };
    greyOverride: Palette['grey'] & {
      secondary: React.CSSProperties['color'];
      tooltip: React.CSSProperties['color'];
    };
    greyFilter: Palette['grey'];
    quokkapedia: Palette['primary'];
  }

  interface PaletteOptions {
    accent: {
      primary: React.CSSProperties['color'];
      primaryFilter: React.CSSProperties['color'];
      100: string;
      200: string;
      400: string;
      500: string;
      filter500: string;
      gold: string;
      redOrange: string;
    };
    base: {
      maroon: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      grey: React.CSSProperties['color'];
      orange: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
      black: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      disabled: React.CSSProperties['color'];
    };
    state: {
      error: React.CSSProperties['color'];
      danger: React.CSSProperties['color'];
      info: React.CSSProperties['color'];
      success: React.CSSProperties['color'];
      warning: React.CSSProperties['color'];
      errorFilter: React.CSSProperties['color'];
      dangerFilter: React.CSSProperties['color'];
      infoFilter: React.CSSProperties['color'];
      successFilter: React.CSSProperties['color'];
      warningFilter: React.CSSProperties['color'];
    };

    chart: {
      amber: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      blueGrey: React.CSSProperties['color'];
      brown: React.CSSProperties['color'];
      cyan: React.CSSProperties['color'];
      deepOrange: React.CSSProperties['color'];
      deepPurple: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      grey: React.CSSProperties['color'];
      indigo: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
      lime: React.CSSProperties['color'];
      orange: React.CSSProperties['color'];
      pink: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      teal: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
    };
    greyOverride: PaletteOptions['grey'] & {
      secondary: React.CSSProperties['color'];
      tooltip: React.CSSProperties['color'];
    };
    greyFilter: PaletteOptions['grey'];
    quokkapedia?: PaletteOptions['primary'];
  }

  interface Theme {
    elevation: {
      ['2dp']: string;
      ['4dp']: string;
    };
  }

  interface ThemeOptions {
    elevation?: {
      ['2dp']?: string;
      ['4dp']?: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Partial<Record<KwTypography | KwFormTypography, boolean>> {
    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
  }
}

// TODO: build out whole theme object
// Create a theme instance.
const theme = createTheme({
  typography: palette => ({
    h1BoldXLarge: {
      fontSize: '56px',
      lineHeight: '49px',
      color: '#4B4B4B',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '42px',
      },
    },
    h1Bold: {
      fontSize: '35px',
      lineHeight: '49px',
      color: '#4B4B4B',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '28px',
      },
    },
    h1Medium: {
      fontSize: '35px',
      lineHeight: '49px',
      color: '#4B4B4B',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '28px',
      },
    },
    h1Regular: {
      fontSize: '35px',
      lineHeight: '49px',
      color: '#4B4B4B',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '28px',
      },
    },
    h1Light: {
      fontSize: '35px',
      lineHeight: '49px',
      color: '#4B4B4B',
      fontWeight: 300,
      '@media (max-width: 960px)': {
        fontSize: '28px',
      },
    },
    h2Bold: {
      fontSize: '33px',
      lineHeight: '45px',
      color: '#4B4B4B',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '24px',
      },
    },
    h2Medium: {
      fontSize: '33px',
      lineHeight: '45px',
      color: '#4B4B4B',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '24px',
      },
    },
    h2Regular: {
      fontSize: '33px',
      lineHeight: '45px',
      color: '#4B4B4B',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '24px',
      },
    },
    h2Light: {
      fontSize: '33px',
      lineHeight: '45px',
      color: '#4B4B4B',
      fontWeight: 300,
      '@media (max-width: 960px)': {
        fontSize: '24px',
      },
    },
    h3Bold: {
      fontSize: '24px',
      lineHeight: '32px',
      color: '#424242',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '21px',
      },
    },
    h3Medium: {
      fontSize: '24px',
      lineHeight: '32px',
      color: '#4B4B4B',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '21px',
      },
    },
    h3Regular: {
      fontSize: '24px',
      lineHeight: '32px',
      color: '#4B4B4B',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '21px',
      },
    },
    h3Light: {
      fontSize: '24px',
      lineHeight: '32px',
      color: '#4B4B4B',
      fontWeight: 300,
      '@media (max-width: 960px)': {
        fontSize: '21px',
      },
    },
    h4Bold: {
      fontSize: '21px',
      lineHeight: '28px',
      color: '#424242',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '18px',
      },
    },
    h4Medium: {
      fontSize: '21px',
      lineHeight: '28px',
      color: '#424242',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '18px',
      },
    },
    h4Regular: {
      fontSize: '21px',
      lineHeight: '28px',
      color: '#424242',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '18px',
      },
    },
    h4Light: {
      fontSize: '21px',
      lineHeight: '28px',
      color: '#424242',
      fontWeight: 300,
      '@media (max-width: 960px)': {
        fontSize: '18px',
      },
    },
    h5Bold: {
      fontSize: '18px',
      lineHeight: '25px',
      color: '#424242',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '16px',
      },
    },
    h5Medium: {
      fontSize: '18px',
      lineHeight: '25px',
      color: '#424242',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '16px',
      },
    },
    h5Regular: {
      fontSize: '18px',
      lineHeight: '25px',
      color: '#424242',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '16px',
      },
    },
    h5Light: {
      fontSize: '18px',
      lineHeight: '25px',
      color: '#424242',
      fontWeight: 300,
      '@media (max-width: 960px)': {
        fontSize: '16px',
      },
    },
    h6Bold: {
      fontSize: '14px',
      lineHeight: '19px',
      color: '#424242',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    h6Medium: {
      fontSize: '14px',
      lineHeight: '19px',
      color: '#424242',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    h6Regular: {
      fontSize: '14px',
      lineHeight: '19px',
      color: '#424242',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    h6Light: {
      fontSize: '14px',
      lineHeight: '19px',
      color: '#424242',
      fontWeight: 300,
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    bodyBold: {
      fontSize: '14px',
      fontWeight: 800,
      color: '#424242',
      lineHeight: '21px',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    bodySemiBold: {
      fontSize: '14px',
      fontWeight: 700,
      color: '#424242',
      lineHeight: '21px',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    bodyMedium: {
      fontSize: '14px',
      fontWeight: 600,
      color: '#424242',
      lineHeight: '21px',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    bodyRegular: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#424242',
      lineHeight: '21px',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    bodyLight: {
      fontSize: '14px',
      fontWeight: 300,
      color: '#424242',
      lineHeight: '21px',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    bodyCode: {
      fontSize: '14px',
      lineHeight: '16px',
      fontFamily: 'Courier New',
      fontWeight: 300,
      color: '#212121',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    placeholderPopulated: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#212121',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    placeholderRest: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#818181',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    placeholderDisable: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#9E9E9E',
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    label14Active: {
      fontSize: '14px',
      color: '#212121',
      lineHeight: '20px',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    label14Regular: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#424242',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    label14Disable: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#9E9E9E',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    },
    label12Medium: {
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9E9E9E',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '10px',
      },
    },
    label12Regular: {
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9E9E9E',
      fontWeight: 400,
      '@media (max-width: 960px)': {
        fontSize: '10px',
      },
    },
    smallBold: {
      fontSize: '12px',
      lineHeight: '20px',
      color: '#424242',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '10px',
      },
    },
    smallMedium: {
      fontSize: '12px',
      lineHeight: '20px',
      color: '#424242',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '10px',
      },
    },
    smallRegular: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      color: '#424242',
      '@media (max-width: 960px)': {
        fontSize: '10px',
      },
    },
    smallLight: {
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '20px',
      color: '#424242',
      '@media (max-width: 960px)': {
        fontSize: '10px',
      },
    },
    tinyBold: {
      color: '#818181',
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 800,
      '@media (max-width: 960px)': {
        fontSize: '8px',
      },
    },
    tinyMedium: {
      color: '#818181',
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '8px',
      },
    },
    tinyRegular: {
      color: '#818181',
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        fontSize: '8px',
      },
    },
    tinyLight: {
      color: '#818181',
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 300,
      '@media (max-width: 960px)': {
        fontSize: '8px',
      },
    },
  }),
  palette: {
    quokkapedia: {
      main: '#044E7D',
      light: '#3682C8',
    },
    primary: {
      main: '#487F87',
      light: '#56B093',
    },
    secondary: {
      main: '#FFA38B',
    },
    accent: {
      primary: '#487F87',
      // filter version of theme.palette.accent.primary made using https://codepen.io/sosuke/pen/Pjoqqp and used for images
      primaryFilter: 'invert(46%) sepia(3%) saturate(4388%) hue-rotate(140deg) brightness(96%) contrast(91%)',

      100: '#BFC9F9',
      200: '#5C85F2',
      400: '#1A4DA4',
      filter500: 'invert(13%) sepia(91%) saturate(3628%) hue-rotate(207deg) brightness(83%) contrast(118%)',
      500: '#00318E',
      gold: '#FFCC09',
      redOrange: '#FFA38B',
    },
    state: {
      error: '#F44336',
      danger: '#F4516C',
      info: '#36A3F7',
      success: '#34BFA3',
      warning: '#FFB822',
      errorFilter: 'invert(40%) sepia(72%) saturate(2972%) hue-rotate(337deg) brightness(99%) contrast(112%)',
      dangerFilter: 'invert(43%) sepia(54%) saturate(1620%) hue-rotate(319deg) brightness(97%) contrast(96%)',
      infoFilter: 'invert(54%) sepia(34%) saturate(2377%) hue-rotate(182deg) brightness(100%) contrast(94%)',
      successFilter: 'invert(53%) sepia(80%) saturate(340%) hue-rotate(118deg) brightness(102%) contrast(92%)',
      warningFilter: 'invert(73%) sepia(98%) saturate(617%) hue-rotate(336deg) brightness(99%) contrast(104%)',
    },

    text: {
      primary: '#000000',
      secondary: '#244C5A',
    },
    base: {
      maroon: '#800000',
      blue: '#2196F3',
      green: '#4CAF50',
      grey: '#9E9E9E',
      orange: '#FF9800',
      red: '#F44336',
      yellow: '#FFEB3B',
      black: '#000000',
      white: '#FFFFFF',
      disabled: '#E0E0E0',
    },
    chart: {
      amber: '#FFD54F',
      blue: '#64B5F6',
      blueGrey: '#90A4AE',
      brown: '#A1887F',
      cyan: '#4DD0E1',
      deepOrange: '#FF8A65',
      deepPurple: '#9575CD',
      green: '#81C784',
      grey: '#E0E0E0',
      indigo: '#7986CB',
      lightBlue: '#4FC3F7',
      lightGreen: '#AED581',
      lime: '#DCE775',
      orange: '#FFB74D',
      pink: '#F06292',
      purple: '#9C27B0',
      red: '#EF5350',
      teal: '#4DB6AC',
      yellow: '#FFF176',
    },
    // can't add properties to `grey` attribute (type error), so create `greyOverride` instead
    greyOverride: {
      secondary: '#9E9E9E',
      tooltip: '#3B3E40',

      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      600: '#757575',
      700: '#616161',
      800: '#3B3B3B',

      900: '#121212',
    },
    // filtered version of theme.palette.grey made using https://codepen.io/sosuke/pen/Pjoqqp  and used for images
    greyFilter: {
      600: 'invert(49%) sepia(0%) saturate(9%) hue-rotate(215deg) brightness(90%) contrast(76%)',
      800: 'invert(23%) sepia(0%) saturate(13%) hue-rotate(187deg) brightness(90%) contrast(90%);',
    },
  },
  elevation: {
    '2dp': '0 2px 2px rgba(0 0 0 / 14%), 0 3px 1px -2px rgba(0 0 0 / 12%), 0 1px 5px rgba(0 0 0 / 20%)',
    '4dp': '0 4px 5px rgba(0 0 0 / 14%), 0 1px 10px rgba(0 0 0 / 12%), 0 2px 4px -1px rgba(0 0 0 / 20%)',
  },
});

export { theme };
