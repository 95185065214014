import { ThemeOptions, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';

/**
 * Please use this in place of MUI's createTheme() function. This is an abstract function
 * of creating and merging the custom theme options with the global theme.
 *
 * Without this, there would be an issue with nesting themes because the component's theme
 * would override the global theme i.e. the text fonts.
 *
 * @param {ThemeOptions} options - The custom theme options for the component.
 * @returns {Theme} The merged component theme, incorporating both local and global settings.
 */
export const createQTheme = (options?: ThemeOptions) => {
  return createTheme(deepmerge(options, theme));
};
