import { ThemeProvider } from '@mui/material/styles';
import { Link, ListItemText, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, alpha } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';
import { createQTheme } from '../utils/createQTheme';

const getListTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            width: 'auto',
            minWidth: '356px',
            padding: '16px',
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            width: 'auto',
            minWidth: '356px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: palette.base.black,
            fontSize: '14px',
            backgroundColor: palette.greyOverride[200],
            borderBottom: 'none',
            minWidth: '141px',
            ':first-of-type': {
              borderRadius: '4px 0px 0px 0px',
            },
            ':last-child': {
              borderRadius: '0px 4px 0px 0px',
            },
          },
          body: {
            fontSize: '12px',
          },
          alignRight: {
            textAlign: 'right',
          },
          alignCenter: {
            textAlign: 'center',
          },
          footer: {
            borderBottom: 'none',
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontWeight: '400',
            color: palette.primary.main,
            cursor: 'pointer',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            minWidth: '356px',
            '&:hover': {
              backgroundColor: alpha(palette.primary.main, 0.2),
            },
            borderSpacing: '34px',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: palette.base.black,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '16px',
          },
          secondary: {
            color: palette.base.black,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '16px',
          },
        },
      },
    },
  });

export const QList = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Table {...props}>{children}</Table>
    </ThemeProvider>
  );
};

export const QListHead = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TableHead {...props}>{children}</TableHead>
    </ThemeProvider>
  );
};

export const QListCell = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TableCell {...props}>{children}</TableCell>
    </ThemeProvider>
  );
};

export const QListRow = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TableRow {...props}>{children}</TableRow>
    </ThemeProvider>
  );
};

export const QListBody = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TableBody {...props}>{children}</TableBody>
    </ThemeProvider>
  );
};

export const QListFooter = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TableFooter {...props}>{children}</TableFooter>
    </ThemeProvider>
  );
};

export const QLink = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Link {...props}>{children}</Link>
    </ThemeProvider>
  );
};

export const QListItemText = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getListTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ListItemText {...props}>{children}</ListItemText>
    </ThemeProvider>
  );
};
