import { ThemeProvider, Typography, TypographyProps, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1BoldXLarge: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1BoldXLarge?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1BoldXLarge: true;
  }
}

const typograthyTheme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          h1BoldXLarge: 'h1',
        },
      },
    },
  },
});

export const QTypography = ({ children, ...props }: TypographyProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(typograthyTheme, outerTheme)}>
      <Typography {...props}>{children}</Typography>
    </ThemeProvider>
  );
};
