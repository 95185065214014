import {
  ImageListItem,
  ImageListItemProps,
  InputAdornment,
  InputAdornmentProps,
  TextField,
  TextFieldProps,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { deepmerge } from '@mui/utils';

import { theme } from '../../theme';

const getTextFieldTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
        variants: [
          {
            props: {
              variant: 'standard',
            },
            style: {
              '.MuiInputLabel-root': {
                order: '1',
                height: '25px',
                fontSize: '18px',
                lineHeight: '25px',
                color: palette.base.black,
                fontWeight: '600',
                transform: 'scale(1)',
                position: 'relative',
                '&.Mui-focused': {
                  color: palette.base.black,
                },
                '&.Mui-error': {
                  color: palette.base.black,
                },
              },
              '.MuiInput-root': {
                order: '3',
                marginTop: '0px !important',
                padding: '4px 8px 4px 8px',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: palette.greyOverride[400],
                fontSize: '14px',
                fontWeight: '400',
                '&.Mui-focused': {
                  borderColor: palette.primary.main,
                },
                '&:hover': {
                  borderColor: palette.primary.main,
                },
                '&.Mui-error': {
                  borderColor: palette.state.error,
                },
              },
              '.MuiInputBase-input': {
                padding: '0px',
              },
              '.MuiFormHelperText-root': {
                order: '2',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: palette.base.black,
                marginTop: '0px',
                marginBottom: '4px',
                '&.Mui-error': {
                  color: palette.base.black,
                },
              },
            },
          },

          {
            props: {
              variant: 'outlined',
            },
            style: {
              '.MuiInputLabel-root': {
                order: '1',
                height: '25px',
                fontSize: '18px',
                lineHeight: '25px',
                color: palette.base.black,
                fontWeight: '600',
                transform: 'scale(1)',
                position: 'relative',
                '&.Mui-focused': {
                  color: palette.base.black,
                },
                '&.Mui-error': {
                  color: palette.base.black,
                },
              },
              '.MuiOutlinedInput-root': {
                order: '2',
                marginTop: '0px !important',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: palette.greyOverride[400],
                padding: '4px 8px 4px 8px',
                fontSize: '14px',
                fontWeight: '400',
                '&.Mui-focused': {
                  borderColor: palette.primary.main,
                },
                '&:hover': {
                  borderColor: palette.primary.main,
                },
                '&.Mui-error': {
                  borderColor: palette.state.error,
                },
              },
              '.MuiOutlinedInput-input': {
                padding: '0px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
              '.MuiFormHelperText-root': {
                order: '3',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
                color: palette.greyOverride[600],
                margin: '0px',
                '&.Mui-error': {
                  color: palette.greyOverride[600],
                },
              },
            },
          },
          {
            props: {
              variant: 'filled',
            },
            style: {
              '.MuiFilledInput-root': {
                borderRadius: '4px',
                padding: '5px 9px 4px 9px',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: palette.greyOverride[200],
                '&:hover': {
                  backgroundColor: palette.greyOverride[200],
                },
                alignItems: 'center',
              },
              '.MuiFilledInput-input': {
                padding: '4px 0px 5px 0px',

                '&::placeholder': {
                  color: theme.typography.placeholderRest.color,
                  opacity: 1,
                },
              },
              '.MuiInputAdornment-root': {
                marginTop: '0px !important',
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            display: 'flex',
          },
        },
      },
      MuiInput: {
        defaultProps: {
          disableUnderline: true,
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
      },
      MuiImageListItem: {
        styleOverrides: {
          root: {
            width: '20px',
            height: '20px',
          },
        },
      },
    },
  });

const outlinedWithTitleTheme = ({ palette = theme.palette }) =>
  createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
        variants: [
          {
            props: {
              variant: 'outlined',
            },
            style: {
              '.MuiInputLabel-root': {
                color: palette.greyOverride[600],
              },
              '.MuiInputLabel-shrink': {
                color: palette.primary.main,
              },
              '.MuiInputLabel-shrink .Mui-error': {
                color: palette.state.error,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            display: 'flex',
          },
        },
      },
    },
  });

interface IProps extends ImageListItemProps {
  iconSrc?: string;
}

export const QTextField = ({ children, outlinedWithTitle, ...props }: TextFieldProps & { outlinedWithTitle?: boolean }) => {
  return outlinedWithTitle && (!props.variant || props.variant === 'outlined') ? (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(outlinedWithTitleTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TextField {...props}>{children}</TextField>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTextFieldTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <TextField {...props} InputProps={{ ...props.InputProps, sx: props.size === 'small' ? { height: '32px' } : { height: '40px' } }}>
        {children}
      </TextField>
    </ThemeProvider>
  );
};

export const QTextFieldIconWrapper = ({ children, ...props }: InputAdornmentProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTextFieldTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <InputAdornment {...props}>{children}</InputAdornment>
    </ThemeProvider>
  );
};

export const QTextFieldIcon = ({ iconSrc, children, ...props }: IProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getTextFieldTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ImageListItem {...props}>
        <img src={iconSrc}></img>
      </ImageListItem>
    </ThemeProvider>
  );
};
