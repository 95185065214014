import { Pagination, PaginationItem, PaginationProps, ThemeProvider } from '@mui/material';
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { deepmerge } from '@mui/utils';

import { createQTheme } from '../utils/createQTheme';
import { theme } from '../../theme';

const getPaginationTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiPagination: {
        styleOverrides: {
          ul: { gap: '8px' },
        },
        defaultProps: {
          shape: 'rounded',
          size: 'small',
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          sizeSmall: {
            fontSize: '12px',
            padding: '0px',
            margin: '0px',
            minWidth: '24px',
            width: '24px',
            height: '24px',
          },
          rounded: {
            backgroundColor: palette.greyOverride[100],
            '&.Mui-selected': {
              backgroundColor: palette.primary.main,
              color: palette.base.white,
            },
          },
          ellipsis: {
            background: 'none',
          },
          previousNext: {
            backgroundColor: palette.base.white,
            border: '1.5px',
            borderStyle: 'solid',
            borderColor: palette.primary.main,
          },
        },
      },
    },
  });

export const QPagination = ({ ...props }: PaginationProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getPaginationTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Pagination
        renderItem={item => <PaginationItem slots={{ previous: KeyboardDoubleArrowLeft, next: KeyboardDoubleArrowRight }} {...item} />}
        {...props}
      />
    </ThemeProvider>
  );
};
