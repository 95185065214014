import { FormControlProps, InputLabelProps, MenuItemProps } from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { QDropdownWrapper, DropdownOptionType } from '../QDropdown/QDropdown';

interface QActionSelectProps {
  options?: DropdownOptionType;
  closedLabel?: any;
  formControlProps?: FormControlProps;
  labelProps?: InputLabelProps;
  selectProps?: SelectProps;
  itemProps?: MenuItemProps;
  labelButtonProps?: any;
}

export const QActionSelect = ({
  options = [],
  closedLabel = '',
  formControlProps = {},
  labelProps = {},
  selectProps = {},
  itemProps = {},
  labelButtonProps = null,
}: QActionSelectProps) => {
  return (
    <QDropdownWrapper
      options={options}
      closedLabel={closedLabel}
      formControlProps={formControlProps}
      labelProps={labelProps}
      selectProps={selectProps}
      itemProps={itemProps}
      labelButtonProps={labelButtonProps}
    />
  );
};
