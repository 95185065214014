import {
  Divider,
  Drawer,
  DrawerProps,
  Fab,
  FabProps,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  ListProps,
  ListSubheader,
  ListSubheaderProps,
  ThemeProvider,
  Toolbar,
  ToolbarProps,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';
import { createQTheme } from '../utils/createQTheme';

const getSidebarTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiDrawer: {
        defaultProps: {
          open: true,
          hideBackdrop: true,
        },
        variants: [
          {
            props: {
              variant: 'temporary',
            },
            style: {
              '.MuiListItem-root': {
                width: '62px',
                maxWidth: '62px',
              },
              '.MuiToolbar-root': {
                paddingLeft: '0px',
                paddingRight: '0px',
              },
            },
          },
          {
            props: {
              variant: 'permanent',
            },
            style: {
              '.MuiListItem-root': {
                width: '224px',
              },
            },
          },
        ],
        styleOverrides: {
          paper: {
            backgroundColor: palette.text.secondary,
            color: palette.base.white,
          },
          root: {
            zIndex: '1',
            position: 'relative',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            display: 'flex',
            paddingLeft: '0px',
            paddingRight: '0px',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: palette.base.white,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundColor: palette.text.secondary,
            fontSize: '10px',
            color: palette.greyOverride[100],
            textTransform: 'uppercase',
            height: '14px',
            marginTop: '8px',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
      MuiListItem: {
        defaultProps: {
          disablePadding: true,
        },
        styleOverrides: {
          root: {
            color: palette.base.white,
            fontSize: '14px',
            lineHeight: 'normal',
            height: '56px',
            maxHeight: '56px',
            width: '100%',
          },
        },
      },
      MuiListItemButton: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            padding: '0',
            ':hover': {
              '::before': {
                content: '""',
                width: '6px',
                height: '56px',
                backgroundColor: palette.accent.redOrange,
              },
              '.MuiListItemText-root': {
                color: palette.accent.redOrange,
              },
              '.MuiListItemIcon-root': {
                color: palette.accent.redOrange,
              },
            },
            ':focus': {
              '::before': {
                content: '""',
                width: '6px',
                height: '56px',
                backgroundColor: palette.accent.redOrange,
              },
              '.MuiListItemText-root': {
                color: palette.accent.redOrange,
              },
              '.MuiListItemIcon-root': {
                color: palette.accent.redOrange,
              },
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: palette.base.white,
            marginLeft: '16px',
            marginRight: '16px',
            minWidth: '0',
            '.MuiSvgIcon-root': {
              width: '24px',
              height: '24px',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            marginRight: '20px',
            maxWidth: '140px',
            fontSize: '14px',
          },
        },
      },
    },
  });

const getFabTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiFab: {
        variants: [
          {
            props: {
              variant: 'expanded',
            },
            style: {
              top: '12px',
              left: '206px',
            },
          },
          {
            props: {
              variant: 'collapsed',
            },
            style: {
              top: '12px',
              left: '44px',
            },
          },
        ],
        styleOverrides: {
          root: {
            backgroundColor: palette.base.white,
            width: '36px',
            height: '36px',
            position: 'absolute',
            zIndex: '2',
          },
        },
      },
    },
  });

declare module '@mui/material/Fab' {
  interface FabPropsVariantOverrides {
    collapsed: true;
    expanded: true;
  }
}

interface QFabProps extends FabProps {
  variant?: 'collapsed' | 'expanded';
}

interface IProps extends DrawerProps {
  fab?: any;
  toolbar?: any;
}
export const QSidebar = ({ fab, toolbar, children, ...props }: IProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      {fab}
      <Drawer {...props}>
        {toolbar}
        {toolbar === undefined ? <></> : <Divider></Divider>}
        {children}
      </Drawer>
    </ThemeProvider>
  );
};

export const QToolbar = ({ children, ...props }: ToolbarProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Toolbar {...props}>{children}</Toolbar>
    </ThemeProvider>
  );
};

export const QSidebarList = ({ children, ...props }: ListProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <List {...props}>{children}</List>
    </ThemeProvider>
  );
};

export const QSidebarSubHeader = ({ children, ...props }: ListSubheaderProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ListSubheader {...props}>{children}</ListSubheader>
    </ThemeProvider>
  );
};

export const QSidebarItem = ({ children, ...props }: ListItemProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ListItem {...props}>{children}</ListItem>
    </ThemeProvider>
  );
};

export const QSidebarItemButton = ({ children, ...props }: ListItemButtonProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ListItemButton {...props}>{children}</ListItemButton>
    </ThemeProvider>
  );
};

export const QSidebarItemIcon = ({ children, ...props }: ListItemIconProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ListItemIcon {...props}>{children}</ListItemIcon>
    </ThemeProvider>
  );
};

export const QSidebarItemText = ({ children, ...props }: ListItemTextProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSidebarTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <ListItemText {...props}>{children}</ListItemText>
    </ThemeProvider>
  );
};

export const QSidebarFab = ({ children, ...props }: QFabProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getFabTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Fab {...props}>{children}</Fab>
    </ThemeProvider>
  );
};
