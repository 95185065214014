import { TableRow, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { createQTheme } from '../utils/createQTheme';

export const QTableRow = ({ children, ...props }: any) => {
  const tableRowTheme = createQTheme({
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            backgroundColor: '#fafafa',
            '&:hover': {
              backgroundColor: '#eeeeee',
              cursor: 'pointer',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={outerTheme => deepmerge(tableRowTheme, outerTheme)}>
      <TableRow {...props}>{children}</TableRow>
    </ThemeProvider>
  );
};
