import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { createQTheme } from '../utils/createQTheme';
import { theme } from '../../theme';

const getSwitchTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 30,
            height: 20,
            padding: 0,
            margin: 8,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 4,
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: 'translateX(10px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  backgroundColor: palette.primary.main,
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-disabled .MuiSwitch-thumb .MuiSwitch-track': {
                color: palette.greyOverride[400],
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 12,
              height: 12,
              verticalAlign: 'middle',
            },
            '& .MuiSwitch-track': {
              borderRadius: 30 / 2,
              backgroundColor: palette.base.black,
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          },
        },
      },
    },
  });

export const QSwitch = ({ ...props }: SwitchProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSwitchTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Switch {...props} />
    </ThemeProvider>
  );
};

export const QSwitchControlLabel = ({ ...props }: FormControlLabelProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getSwitchTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <FormControlLabel {...props} />
    </ThemeProvider>
  );
};
