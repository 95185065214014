import { Card, ThemeProvider, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

export const QCard = ({ children, ...props }: any) => {
  const cardTheme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
            boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
            backgroundColor: '#FAFAFA',
            padding: '8px',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={outerTheme => deepmerge(cardTheme, outerTheme)}>
      <Card {...props}>{children}</Card>
    </ThemeProvider>
  );
};
