import { ThemeProvider } from '@mui/material/styles';
import { ToggleButtonGroup, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';

export const QToggleButtonGroup = ({ children, ...props }: any) => {
  const buttonGroupTheme = createTheme({
    components: {
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            borderRadius: '4px',
            textTransform: 'capitalize',
            height: '36px',
            fontSize: '13px',
            paddingLeft: '4px',
            paddingRight: '4px',
            paddingBottom: '4px',
            paddingTop: '4px',
            alignContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'rgba(36, 76, 90, 0.1)',
            '& .MuiToggleButtonGroup-grouped': {
              border: 0,
              '&.Mui-disabled': {
                border: 0,
              },
              '&.Mui-selected': {
                borderRadius: theme.shape.borderRadius,
                backgroundColor: 'white',
              },
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={outerTheme => deepmerge(buttonGroupTheme, outerTheme)}>
      <ToggleButtonGroup {...props}>{children}</ToggleButtonGroup>
    </ThemeProvider>
  );
};
