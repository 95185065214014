import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Paper, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';
import { createQTheme } from '../utils/createQTheme';

const getModalTheme = (palette = theme.palette) =>
  createQTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: palette.greyOverride[50],
            borderRadius: '4px',
            minWidth: '540px',
            maxWidth: '600px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '24px',
            color: palette.base.black,
            padding: '18px 16px 5px 16px',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            position: 'absolute',
            width: '20px',
            height: '20px',
            right: '16px',
            top: '16px',
            color: palette.base.black,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            fontWeight: '400px',
            lineHeight: '21px',
            fontFamily: theme.typography.fontFamily,
            color: palette.base.black,
            padding: '7px 40px 29px 16px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginLeft: '16px',
            marginRight: '16px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '0px 16px 16px 0px',
          },
        },
      },
    },
  });

export const QModal = ({ title, content, actions, children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getModalTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Paper elevation={2}>
        <Dialog {...props}>
          {title}
          <Divider></Divider>
          {content}
          {actions}
        </Dialog>
      </Paper>
    </ThemeProvider>
  );
};

export const QModalTitle = ({ onClose, children, closeIcon, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getModalTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <DialogTitle {...props}>
        {children}
        <IconButton aria-label="close" onClick={onClose}>
          {closeIcon}
        </IconButton>
      </DialogTitle>
    </ThemeProvider>
  );
};

export const QModalContent = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getModalTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <DialogContent {...props}>{children}</DialogContent>
    </ThemeProvider>
  );
};

export const QModalActions = ({ children, ...props }: any) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getModalTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <DialogActions {...props}>{children}</DialogActions>
    </ThemeProvider>
  );
};
