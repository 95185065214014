import { TableHead, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { createQTheme } from '../utils/createQTheme';

export const QTableHead = ({ children, ...props }: any) => {
  const tableHeadTheme = createQTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-root': {
              padding: '15px 15px 15px',
              cursor: 'pointer',
              fontWeight: 800,
              size: '14px',
              color: 'black',
              backgroundColor: '#F5F5F5',
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={outerTheme => deepmerge(tableHeadTheme, outerTheme)}>
      <TableHead {...props}>{children}</TableHead>
    </ThemeProvider>
  );
};
