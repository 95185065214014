import { Container, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import { deepmerge } from '@mui/utils';

export const QContainer = ({ children, ...props }: any) => {
  const containerTheme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            padding: '36px 28px',
            margin: '0',
            maxWidth: 'unset',
            overflowY: 'auto',
            backgroundColor: 'white',
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={outerTheme => deepmerge(containerTheme, outerTheme)}>
      <Container {...props}>{children}</Container>
    </ThemeProvider>
  );
};
