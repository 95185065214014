import { ThemeProvider, CardHeader, createTheme, Typography } from '@mui/material';
import { CardHeaderProps } from '@mui/material/CardHeader';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';

const getCardHeaderTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: 'h5Medium',
          },
        },
        styleOverrides: {
          content: {
            fontSize: '21px',
            color: palette.text.secondary,
          },
        },
      },
    },
  });

interface IProps extends CardHeaderProps {
  title?: any;
  subheader?: any;
}

export const QCardHeader = ({ title, subheader, ...props }: IProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getCardHeaderTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <CardHeader
        {...props}
        title={<Typography sx={{ fontSize: 'unset' }}>{title}</Typography>}
        subheader={<Typography sx={{ fontSize: 'unset' }}>{subheader}</Typography>}
      />
    </ThemeProvider>
  );
};
